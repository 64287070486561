.mlb-marketing-email-opt-in {
    margin-top: 32px; /* Adjust this value as needed */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
}

.email-signup-toggle {
    color: #04092C;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    margin-bottom: 0; /* Override any default margin */
    cursor: pointer;
}

.mlb-subscribe-text {
    color: #04092C;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.mlb-email-address {
    color: #04092C;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.mlb-unsubscribe-text {
    align-self: stretch;
    color: #717488;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}


.mlb-subscribe-btn {
    display: flex;
    height: 48px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;

    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    font-feature-settings: 'clig' off, 'liga' off;
    cursor: pointer;
}

.mlb-subscribe-btn.subscribe {
    border: 1px solid #D3D3DC;
    background: #FFF;
    color: #04092C;
}

.mlb-subscribe-btn.subscribed {
    background: #F6F6FB;
    color: #474B5E;
    /*cursor: not-allowed; !* Indicate that the button is disabled *!*/
}

.mlb-subscribe-btn:disabled {
    opacity: 0.6;
}
