@media print {
	* {
		text-shadow: none !important;
		color: #000 !important;
		background: transparent !important;
		box-shadow: none !important;
	}
	@page {
		margin: 0.25in;
	}
	a,  a:visited {
		text-decoration: underline;
	}
	a:after {
		display: none;
	}
	abbr[title]:after {
		content: " (" attr(title) ")";
	}
	.ir a:after,  a[href^="javascript:"]:after,  a[href^="#"]:after {
		content: "";
	}
	pre,  blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr,  img {
		page-break-inside: avoid;
	}
	img {
		max-width: 100% !important;
	}
	p,  h2,  h3 {
		orphans: 3;
		widows: 3;
	}
	h2,  h3 {
		page-break-after: avoid;
	}
	.visible-print,
	.hidden-print,
	.account-head {
		display: inherit !important;
	}
	.widget,
	.houseItem {
		border: none;
	}
	.search-main,
	.footer-main,
	.mini-sitemap,
	.navbar,
	.account-controls {
		display: none;
	}
	.checkout .foot-copy,
	ul.checkout-security,
	.checkout .contactUs img,
	.checkout .socialTab,
	.checkout .orderNotes i {
		display: none;
	}
	.checkout .orderNotes .widgetContent > div {
		margin: 10px 10px 10px 0;
	}
	.checkout .head-title,  .houseTitle {
		padding: 9px 0 8px 15px;
	}
}
