#toast-container {
    background-color: #ffedb3;
    padding: 1em 0;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 32px auto;
    grid-template-columns: 32px auto;
    z-index: 1000;
    position: relative;
}

#toast-container p {
    color: rgb(0, 0, 0);
    font-size: 12px;
    font-family: Helvetica, sans-serif;
    letter-spacing: 0px;
    align-self: center;
    padding: 0 0.3rem 0 0.4rem;
    margin: 0 0 0;
    -ms-grid-column: 2;
    -ms-grid-row-align: center;
}

#toast-icon {
    align-self: center;
    justify-self: center;
    -ms-grid-column: 1;
    -ms-grid-column-align: center;
    -ms-grid-row-align: center;
}
