.bootstrap-select.btn-group,
.bootstrap-select.btn-group[class*="span"] {
	float: none;
	display: inline-block;
	margin-bottom: 10px;
	margin-left: 0;
}
.form-search .bootstrap-select.btn-group,
.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group {
	margin-bottom: 0;
}
.bootstrap-select.btn-group.pull-right,
.bootstrap-select.btn-group[class*="span"].pull-right,
.row-fluid .bootstrap-select.btn-group[class*="span"].pull-right {
	float: right;
}
.input-append .bootstrap-select.btn-group {
	margin-left: -1px;
}
.input-prepend .bootstrap-select.btn-group {
	margin-right: -1px;
}
.bootstrap-select:not([class*="span"]) {
		width:100%;
}
.bootstrap-select.regionSelect {
	/*width: 265px;*/
	width:auto;
	margin-bottom:0;
	position: relative;
}
.bootstrap-select.regionSelect .dropdown-menu.inner {
	overflow: hidden;	
}
.bootstrap-select > .btn {
	width: 100%;
}
.error .bootstrap-select .btn {
	border: 1px solid #b94a48;
}
.bootstrap-select.show-menu-arrow.open > .btn {
	z-index: 1001;
}
.bootstrap-select .btn:focus {
	outline: thin dotted #333333 !important;
	outline: 5px auto -webkit-focus-ring-color !important;
	outline-offset: -2px;
}
.bootstrap-select.btn-group .btn .filter-option {
	/*overflow: hidden;
	position: absolute;
	left: 12px;
	right: 25px;*/
	padding-right:10px;
	text-align: left;
	width: 95%;
	overflow: hidden;
	text-wrap: nowrap;
	text-overflow: ellipsis;
}
.bootstrap-select.btn-group .btn .caret {
	position: absolute;
	right: 12px;
}
.bootstrap-select.btn-group > .disabled,
.bootstrap-select.btn-group .dropdown-menu li.disabled > a {
	cursor: not-allowed;
}
.bootstrap-select.btn-group > .disabled:focus {
	outline: none !important;
}
.bootstrap-select.btn-group[class*="span"] .btn {
	width: 100%;
}
.bootstrap-select.btn-group .dropdown-menu {
	min-width: 100%; 
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	right: 0 !important;
}
.lt-ie9 .bootstrap-select.btn-group .dropdown-menu {
	min-width: 94%;
	margin: 0 auto;
	padding: 5px 3% !important;
}
.regionSelect.bootstrap-select.btn-group .dropdown-menu,
.advancedSearchRegionFilter.bootstrap-select.btn-group .dropdown-menu { 
	min-width: auto;
	width: 265px; /* Thank you Edmunton and Calgary */
	max-height: 520px !important;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
	font-size: .925em;
	position: static;
	border: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	padding: 10px;
	margin: 0;
}
.lt-ie9 .bootstrap-select.btn-group .dropdown-menu.inner {
	padding: 0;
}

.lt-ie9 .bootstrap-select.btn-group .dropdown-menu.inner > li {
	display: block;
	width: 100%;
}
.bootstrap-select.btn-group .dropdown-menu dt {
	display: block;
	padding: 3px 20px;
	cursor: default;
}
.bootstrap-select.btn-group .div-contain {
	overflow: hidden;
}
.bootstrap-select.btn-group .dropdown-menu li {
	position: relative;
}
.bootstrap-select.btn-group .dropdown-menu li > a.opt {
	padding-left: 35px;
}
.bootstrap-select.btn-group .dropdown-menu li > a {
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	-khtml-border-radius: 3px;
	border-radius: 3px;	
	min-height: 20px;
	cursor: pointer;
	margin: 0 auto;
}
.bootstrap-select.btn-group .dropdown-menu li > dt small {
	font-weight: normal;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a i.check-mark {
	display: inline-block;
	position: absolute;
	right: 15px;
	margin-top: 2.5px;
}
.bootstrap-select.btn-group .dropdown-menu li a i.check-mark {
	display: none;
}
.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
	margin-right: 34px;
}
.bootstrap-select.btn-group .dropdown-menu li small {
	padding-left: 0.5em;
}
.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) > a:hover small,
.bootstrap-select.btn-group .dropdown-menu li:not(.disabled) > a:focus small {
	color: #64b1d8;
	color: rgba(255,255,255,0.4);
}
.bootstrap-select.btn-group .dropdown-menu li > dt small {
	font-weight: normal;
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
	content: '';
	display: inline-block;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #CCC;
	border-bottom-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	bottom: -4px;
	left: 9px;
	display: none;
}
.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
	content: '';
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid white;
	position: absolute;
	bottom: -4px;
	left: 10px;
	display: none;
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
	bottom: auto;
	top: -3px;
	border-top: 7px solid #ccc;
	border-bottom: 0;
	border-top-color: rgba(0, 0, 0, 0.2);
}
.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
	bottom: auto;
	top: -3px;
	border-top: 6px solid #ffffff;
	border-bottom: 0;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
	right: 12px;
	left: auto;
}
.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
	right: 13px;
	left: auto;
}
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
	display: block;
}

.headerLocation .btn{
	background-color:transparent;
	background-image:none;
	border:none;
	border-radius:0 !important;
	box-shadow:none;
	color:#fff;
	font-size:13px;
	font-weight:bold;
	letter-spacing:1px;
	padding:10px 15px;
	text-align:right;
	text-shadow:0 -1px 0 #484C4F;
	text-transform:uppercase;
}
.headerLocation .btn:hover,
#nav .regionSelect.open .btn.dropdown-toggle {
	color:#fff;
	background-color: #c11b20;
	/*background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2RiMjEyNyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjMTFiMjAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);*/
	background-image: -moz-linear-gradient(top,	#db2127 0%, #c11b20 100%);
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#db2127), color-stop(100%,#c11b20));
	background-image: -webkit-linear-gradient(top,	#db2127 0%,#c11b20 100%);
	background-image: -o-linear-gradient(top,	#db2127 0%,#c11b20 100%);
	background-image: -ms-linear-gradient(top, #db2127 0%,#c11b20 100%);
	background-image: linear-gradient(to bottom, #db2127 0%,#c11b20 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#db2127', endColorstr='#c11b20',GradientType=0 );
}
.navbar .btn-group.headerLocation {
	margin:0;
}

.headerLocation > .btn > .filter-option{ text-align:right !important; }

.headerLocation > .btn > .caret,
.headerLocation > .btn:hover > .caret {
  border-bottom-color: #fff;
  border-top-color: #fff;
}
.headerLocation .dropdown-menu{
	left: auto;
	right: 0;
	width:300px;
}
.headerLocation .dropdown-menu.inner {
	padding-right: 10px !important;
}
