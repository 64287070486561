#card-container {
    background: rgb(255, 255, 255);
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
    z-index: 1000;
    position: relative;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 100%;
    -ms-grid-rows: 46px 31px 52px;
    grid-template-rows: 46px 31px 52px;
}

#card-icon {
    align-self: end;
    -ms-grid-row-align: end;
    justify-self: center;
    -ms-grid-column-align: center;
    -ms-grid-row: 1;
}

#card-title {
    align-self: end;
    -ms-grid-row-align: end;
    justify-self: center;
    -ms-grid-column-align: center;
    -ms-grid-row: 2;
}

#card-title p {
    height: 21px;
    color: rgb(51, 51, 51);
    font-size: 20px;
    font-family: Helvetica, sans-serif;
    text-align: center;
    letter-spacing: 0px;
    line-height: 21px;
    margin: 0 0 0;
}

#card-content {
    justify-self: center;
    -ms-grid-column-align: center;
    -ms-grid-row: 3;
}

#card-content p {
    height: 20px;
    color: rgb(51, 51, 51);
    font-size: 14px;
    font-family: Helvetica, sans-serif;
    text-align: center;
    letter-spacing: 0px;
    line-height: 20px;
    margin: 5px 0 0;
}
