/**************************************************************************
@Custom Engagement Styles
**************************************************************************/

/* clear-fix. overflow:hidden to positioned elements like pop-overs are not
 enclosed by the container.
TODO- integrate with main code after Jerry/Ryan Discuss */
.float_container:after {
	content: " ";
	display: block;
	height: 0;
	clear: both;
	overflow: hidden;
	visibility: hidden;
}
#toggle_ce {
	position: absolute;
	top:1px;
	right:5px;
	padding: 0;
	margin: 0;
	z-index: 18998;
}
#custom_engagement_container {
	position:fixed;
	left:5px;
	bottom:10px;
	background:#FFF;
	z-index:18998;
	display:none;
}
#custom_engagement_wrapper {
	font-size: 0.929em; /* 13px */
	width:315px;
}
#ce_header {
	background-color:#404040;
	height:30px;
}
#ce_header .xsell_logo {
	float:left;
	height:25px;
	width:36px;
}
#ce_header p {
	color:#fff;
	float:left;
	line-height:30px;
	margin-left:10px;
}
#ce_header_ctrls {
	float:right;
}
#ce_header_ctrls #ce_min {
	float:left;
	margin:0px;
}
#ce_header_ctrls #ce_close {
	float:left;
	margin:0px;
}
#ce_content_wrapper {
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	color: #868686;
}
#ce_content_wrapper ul {
	margin:0;
}
#ce_content_wrapper ul li {
	padding:20px 10px 0 20px;
}
#ce_content_wrapper ul li .left_sect {
	float:left;
	height:40px;
	width:40px;
}
#ce_content_wrapper ul li .center_sect {
	float:left;
	margin:0 0 0 15px;
	position:relative;
	width:200px;
}
#ce_content_wrapper ul li .full_name {
	color:#0077b5;
	font-weight:bold;
}
#ce_content_wrapper ul li .right_sect {
	float:right;
	opacity:.4;
}
#ce_footer {
	border-bottom: 1px solid #ccc;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
	min-height:30px;
}
#ce_footer a {
	color: #069;
	float:right;
	font-size: 0.929em; /* 13px */
	margin-right:20px;
	text-decoration: none;
}

/**************************************************************************
END @Custom Engagement Styles
**************************************************************************/


/**************************************************************************
 Custom Engagement Mini Engagement Infobox
**************************************************************************/

/* Just a hidden dummy button for Demos */
#transparentButton1 {
	color:#fff;
	display:block;
	height:10px;
	width:10px;
	position:fixed;
	top:0px;
	left:0px;
	opacity:.1;
	z-index:20000;
}

/**************************************************************************
 Infobox
 -First Infobox prototype -- refactor as you make this a factory!
**************************************************************************/
/* clear-fix. overflow:hidden to positioned elements like pop-overs are not
 enclosed by the container.
TODO- integrate with main code after Jerry/Ryan Discuss */
.infoboxBtnList {
	float:right;
	margin:5px 5px 0 0;
}
#chartInfoboxContainer {
	position: absolute;
	top: 200px;
	left: 100px;
	width: 10px;
	height: 10px;
	z-index:900000;
}

/* oversize to support proper hover between div and pop-up */
.custom_infobox_container {
	position:absolute;
	display:none;
	z-index:20000;

	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0,0,0,0.2);
	*border-right-width: 2px;
	*border-bottom-width: 2px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-khtml-border-radius: 6px;
	border-radius: 6px;
	-webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
	-moz-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
	box-shadow: 0 5px 10px rgba(0,0,0,0.2);
	padding:24px 20px 14px;
}

.custom_infobox_content{
	font-size:0.786em; /* 11px */
	width:240px;
	line-height: 1.636em; /*18px*/
}
.custom_infobox_content .close{
	position:absolute;
	right:10px;
	top:5px;
}
.custom_infobox_content .bottom_triangle {
	position:absolute;
	bottom:-8px;
	left:100px;
}

/**************************************************************************
@ Custom Infobox Adjustments from Orig Infobox for Engagement
 -for now just leverage the custom engagement Infobox
	e.g. Re-size, Move Triangle depending on position Type.
**************************************************************************/

#chartInfoboxContainer .custom_infobox_content {
	width: 275px;
}
#chartInfoboxContainer .custom_infobox_content .custom_infobox_right_content {
	width:175px;
}
#chartInfoboxContainer .custom_infobox_container {
	width:auto;
	height:auto;
	top:0px;
	left:0px;
}
/*
TODO figure ot a way to handle top/bottom triangle dude.
Just toggle the class.. Ahh.. awesome
*/

#chartInfoboxContainer .custom_infobox_content .bottom_triangle {
	right:0px;
	bottom:-9px;
	left:auto;
	position:absolute;
	width:25px;
	height:11px;
}
#chartInfoboxContainer .custom_infobox_content .top_triangle {
	left:-2px;
	top:-10px;
	position:absolute;
}
/**************************************************************************
@ END Custom Infobox
**************************************************************************/

/**************************************************************************
@ Custom Infobox Adjustments from Gift Infobox.
 -for now just leverage the custom engagement Infobox
	e.g. Re-size, Move Triangle depending on position Type.
**************************************************************************/
#presentation-folder-custom-infobox.custom_infobox_container,
#egift-card-custom-infobox.custom_infobox_container  {
  padding: 10px;
}
.custom_infobox_content .custom_infobox_right_content {
	width:175px;
}
.custom_infobox_content .person_info {
	max-width:140px;
}

/*
TODO figure ot a way to handle top/bottom triangle dude.
Just toggle the class.. Ahh.. awesome
*/

.custom_infobox_content .bottom_triangle {
	right:0px;
	bottom:-9px;
	left:auto;
	position:absolute;
	width:25px;
	height:11px;
}
.custom_infobox_content .top_triangle {
	left:-2px;
	top:-10px;
	position:absolute;
}
/**************************************************************************
@ END Custom Infobox
**************************************************************************/

/**************************************************************************
 End Infobox
**************************************************************************/

/* oversize to support proper hover between div and pop-up */
.mini_profile_container {
	width:400px;
	height:110px;
	position:absolute;
	top: -110px;
	left: -70px;
	display:none;
}
.mini_profile_content .bottom_triangle {
	position:absolute;
	bottom:-8px;
	left:100px;
}
.mini_profile_content	{
	width:400px;
	height:100px;
	background-color:#fff;
	box-shadow: 0 1px 0 0 rgba(0,0,0, 0.08);
	border:1px solid;
	border-color: #ccc #ccc #a7a7a7 #ccc;
}
.mini_profile_content .mini_profile_image {
	float:left;
	height:100%;
	width:100px;
}
.mini_profile_content .mini_profile_right_content {
	float:right;
	width:295px;
	height:95px;
}
.mini_profile_content .person_info {
	float:left;
	padding:10px 0 0 10px;
}
.mini_profile_xsell {
	float:right;
	width: 60px;
	height:12px;
	padding-top:10px;
	padding-right:10px;
}
.mini_profile_content .person_info .full_name {
	color:#000;
	font-weight:bold;
	margin:0px;
	max-width:225px;
	max-height:20px;
	overflow:hidden;
}
.mini_profile_content .person_info .title {
	color:#000;
	font-size:0.857em; /* 12px */
	font-weight: normal;
	text-overflow:ellipsis;
	margin:0px;
	max-width:225px;
	max-height:20px;
	overflow:hidden;
}
.mini_profile_content .button_row {
	padding-left:10px;
	padding-top:10px;
}

/**************************************************************************
@End Custom Infobox
**************************************************************************/

/**************************************************************************
@ Generic Infobox Container
 To get the Venue, and Help Center Infoboxes to work as Generic
	-e.g basic container with the triangle's
	-e.g. Renamed form custom_infobox_container, and custom_infobox_content
**************************************************************************/

.custom_infobox_container.generic {
	position:absolute;
	width:auto;
	height:auto;
	z-index:20000;
}
.custom_infobox_content.generic {
	width:auto;
	width:auto;
	height:auto;
}

/**************************************************************************
@ END Generic Infobox Container
**************************************************************************/

/*==================================================================
	Infobox Nib Styling (the triangle on the pop-ups)
==================================================================*/
[class^="infobox-nib-"],
[class*=" infobox-nib-"] {
	background-image:url("../../images/infobox/infobox-nib-sprite.png");
	background-repeat: no-repeat;
	display:block;
	height:10px;
	overflow:hidden;
	position:absolute;
	width:10px;
}
/* Top Nibs */
.infobox-nib-top-left{ top:-10px; left:10px; background-position:center 0; width:20px; }
.infobox-nib-top-right{ top:-10px; right:10px; background-position:center 0; width:20px; }
.infobox-nib-top-center{ top:-10px; right:0; background-position:center 0; width:100%; }

/* Right Nibs */
.infobox-nib-right-top{ top:10px; right:-10px; background-position:-30px center; height:20px; }
.infobox-nib-right-bottom{ right:-10px; bottom:10px; background-position:-30px center; height:20px; }
.infobox-nib-right-center{ bottom:0; right:-10px; background-position:-30px center; height:100%; }

/* Bottom Nibs */
.infobox-nib-bottom-left{ bottom:-10px; left:10px; background-position:center -30px; width:20px; }
.infobox-nib-bottom-right{ right:10px; bottom:-10px; background-position:center -30px; width:20px; }
.infobox-nib-bottom-center{ bottom:-10px; right: 0; background-position:center -30px; width:100%; }

/* Left Nibs */
.infobox-nib-left-top{ top:10px; left:-10px; background-position:0 center; height:20px; }
.infobox-nib-left-bottom{ bottom:10px; left:-10px; background-position:0 center; height:20px; }
.infobox-nib-left-center{ bottom:0; left:-10px; background-position:0 center; height:100%; }

/*==================================================================
	Custom Infobox Styles
==================================================================*/

.custom_infobox_content .vvHouseDetailsTitle {
	font-weight: bold;
}