/* ADD THIS */

.addthis_counter a.atc_s span {
	color: #fff !important;
	cursor: pointer;
	font-family: arial,helvetica,lucida,tahoma,verdana,sans-serif;
	font-size: 0.714em; /* 10px */
	line-height: 18px;
	text-decoration: none  !important;
	text-transform: none;
}
.addthis_default_style .at300b, .addthis_default_style .at300m {
	padding: 0 1px;
}
.addthis_button_facebook, .addthis_button_google_plusone, .addthis_button_favorites, .addthis_button_email {
	padding:0px 2px !important
}
.addthis_button_tweet {
	margin-left: 8px;
}

/* END ADD THIS */