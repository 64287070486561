/* Child theme styling

Table of Contents

  1. General
  2. Main
  3. Overrides
  4. 3rd Party
      4a. Spacing Helpers
  5. Responsive


/* =====================================

  1. General

====================================== */


html {
  height: 100% !important;
  background-color: #fff;
  overflow: auto !important;
}
body {
  overflow: auto !important;
  height: 100% !important;
  background: #fff;
  background: -moz-linear-gradient(top,  #edebec 0%, #ffffff 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#edebec), color-stop(100%,#ffffff));
  background: -webkit-linear-gradient(top,  #edebec 0%,#ffffff 100%);
  background: -o-linear-gradient(top,  #edebec 0%,#ffffff 100%);
  background: -ms-linear-gradient(top,  #edebec 0%,#ffffff 100%);
  background: linear-gradient(to bottom,  #edebec 0%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#edebec', endColorstr='#ffffff',GradientType=0 );
}

#rocket-miles-banner-link {
  cursor: pointer;
}

.page-wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  margin: 0 auto -100px;
  overflow: auto !important;
}
.custom-footer {
  background: #333;
  padding: 30px 0;
  height: 40px;
  line-height: 1.5;
  color: #ccc;
  text-align: center;
}
.push {
  height: 100px;
}
.bold {font-weight: bold;}

/* fix for iOS */
.tfs-checkout .page-wrapper {
  height: auto !important;
  margin: 0 auto !important;
}
.tfs-checkout .push {
  display: none !important;
}
body.tfs-checkout {
  height: auto !important;
}
.tfs-checkout .custom-footer {
  margin-top: 20px;
  height: auto;
}
@media (max-device-width: 480px) {
 .tfs-checkout .custom-footer {
    margin: 20px 0 100px 0;
  }
}
a {
  color: #1e6f91;
}
a:hover {
  cursor: pointer;
}

/* =====================================

  2. Main

====================================== */

.custom-header {
  margin: 0 auto 15px;
  height: 100px;
  position: relative;
  margin-bottom: 15px;
  padding: 20px 0;
}
.custom-header h1 {
  display: none;
}
.custom-header aside {
  float: right;
  margin-top: 60px;
  font-size: 2em;
  color: #444;
  font-weight: bold;
  text-align: right;
}
.lt-ie8 .custom-header {
  position: static;
}
.lt-ie8 .custom-header aside {
  display: block;
  width: 300px;
  height: 100px;
  text-align: right;
  margin-top: -25px;
}
.aside-live-chat {
  font-size: 18px;
  margin-top: 3px;
}
.aside-live-chat .fa-comment {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  position: relative;
  top: -1px;
  margin-right: 5px;
}
.breadcrumb-feature {
  display: none;
}
.breadcrumbs {
  display: table;
  overflow: hidden;
  margin: 0 auto;
  max-width: 750px;
  padding: 15px 0;
  width: 100%;
}
.breadcrumbs .breadcrumb-stepper {
  color: #999;
  display: table-cell;
  margin: 0;
  max-width: 250px;
  padding: 5px 30px;
  position: relative;
  text-align: center;
  width: 33.33%;
}
.breadcrumbs .breadcrumb-stepper .breadcrumb-step {
  background-color: #fff;
  border: 2px solid #ccc;
  color: #ccc;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  line-height: 20px;
  height: 20px;
  margin: 0 auto;
  text-align: center;
  width: 20px;
}
.breadcrumbs .breadcrumb-stepper .breadcrumb-title {
  color: #a5a5a5;
  font-size: 16px;
  margin-top: 12px;
  text-align: center;
}
.breadcrumbs .breadcrumb-stepper.active-step .breadcrumb-step {
  border-color: #333;
  color: #333;
  font-size: 16px;
  height: 28px;
  line-height: 28px;
  width: 28px;
}
.breadcrumbs .breadcrumb-stepper.active-step .breadcrumb-title {
  font-weight: bold;
  color: #333;
}
.breadcrumbs .breadcrumb-stepper.complete-step .breadcrumb-step {
  background-color: #aaa;
  border-color: #aaa;
  color: #fff;
}
.breadcrumbs .breadcrumb-stepper .breadcrumb-bar-left,
.breadcrumbs .breadcrumb-stepper .breadcrumb-bar-right {
  position: absolute;
  top: 22px;
  height: 2px;
  border-top: 2px solid #ccc;
}

.breadcrumbs .breadcrumb-stepper .breadcrumb-bar-right {
  right: 0;
  left: 50%;
  margin-left: 30px;
}
.breadcrumbs .breadcrumb-stepper .breadcrumb-bar-left {
  left: 0;
  right: 50%;
  margin-right: 30px;
}
.lt-ie8 .well.well-small {
  float: left;
  height: auto;
  width: 95%;
}
.lt-ie8 .input-append.span24 {
  display: block;
  width: 93.25%;
  height: 15px;
  float: left;
  position: static;
}
.lt-ie8 .input-append .noEnterSubmit,
.lt-ie8 .input-append .btn.btn-default {
  position: static;
}

.orderConfirmation .thankYouText {
  padding: 10px 0;
}
.orderConfirmation .widgetContent h4,
.orderConfirmation .widgetContent h3  {
  font-weight: bold;
  font-size: 1.15em;
  color: #555;
  text-transform: uppercase;
  margin-bottom: 1em;
  border-bottom: 1px solid #eee;
}
@media print {
  .custom-header,
  .checkout .span7,
  .print-link {
    display: none !important;
  }
}

.st-header {
  padding: 50px 0;
  text-align: center;
}
.secure-tix {
  font-weight: normal;
}
.st-login .logo-banner {
  min-height: 100px;
}
.sell.st-login .logo-banner {
  visibility: visible !important;
}
.st-404 {
  padding-top: 50px;
  text-align: center;
}
.st-404 h1 {
  font-size: 72px;
  text-align: center;
  font-weight: normal;
  line-height: 1.2;
}
.st-404 h1 span {
  color: #1e6f91;
  font-size: 96px;
}
.st-404 p {
  margin: 3em 0;
}
.st-404 .btn {
  padding: 20px 30px;
}
.st-sd h4.red {
  display: none;
}

.widget {
  background-color: #FFFFFF;
  box-shadow: 0px 3px 4px #ccc;
  margin-bottom: 14px;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.widget .widget {
  box-shadow: none;
  border: none;
}
.head-title {
  display: block;
  border-bottom: 1px solid #ededed;
  font-size: 20px;
  color: #333;
  font-weight: normal;
  padding: 15px;
  margin-top: 0;
}
.widgetTitle .widgetTitleToolBar {
  display: none;
}
.widgetContent {
  padding: 15px;
}
.widgetContent .widgetContent {
  padding: 15px 0;
}
.btn {
  padding: 8px 14px;
}
.btn-primary {
  display: inline-block;
  padding: 7px 12px;
  letter-spacing: 1px;
  color: #fff;
  border: 1px solid #357ebd;
  transition: all .33s ease-in-out !important;
  background-color: #428bca;
  background-image: none;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  color: #fff;
  background: #3071a9;
  border-color: #285e8e;
  text-decoration: none !important;
  background-image: none;
}
.btn-resend-tickets {
  margin-top: 10px;
}
.btn-secondary {
  padding: 7px 12px;
  letter-spacing: 1px;
  background: #e6e6e6;
  transition: all .33s ease-in-out !important;
  text-transform: uppercase;
  font-weight: bold;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.disabled,
.btn-secondary[disabled] {
  background: #dddddd;
  text-decoration: none !important;
  background-image: none;
}

.input-append .btn {
  padding: 4px 12px;
}
.single-order-lookup {
  width: 300px;
  margin: 20px auto;
}
.single-order-lookup h2 {
  margin-top: 0;
}

#checkoutForm .row-fluid {
  margin-bottom: 10px;
}
.form-horizontal .control-label {
  padding-top: 3px;
  font-weight: normal;
  text-transform: uppercase;
  font-size: .85em;
  width: 155px;
}
.form-horizontal .controls {
  margin-left: 165px;
}
input#applyPromo, input#applyGiftCardCode {
  margin-left: -1px;
}
.promo-holder {
  display: none;
}
#removeGiftCards {
  display: inline-block;
  width: 50%;
  text-align: right;
}
@media (min-width: 1200px) {
  #removeGiftCards {
    display: inline;
    margin-left: 5px;
  }
}
.controls label.checkbox,
.controls label.radio {
  line-height: 17px;
}
.controls label.checkbox input,
.controls label.radio input {
  margin-top: 2px;
}



#paypal-bnpl-container, #paypal-container{
  width: 100%;
  display: block;
}

#tfsChargeRollup {
  font-size: 16px;
  background-color: #f2f2f2;
  text-align: center;
  margin: 10px 0;
  padding: 8px;
}

#tfsChargeRollup .charge-line {
  font-weight: bold;
}

#tfsChargeRollup div {
  padding: 2px;
}

#tfsChargeRollup .service-fee-text {
  font-size: 14px;
  color: #777;
}

@media (min-width: 626px) {
  #tfsChargeRollup {
    text-align: left;
    padding-left: 165px;
  }

  #tfsChargeRollup div {
    display: inline-block;
  }
}

.no-float {
  float: none !important;
}

.no-top-margin {
  margin-top: 0 !important;
}

.small-right-margin {
  margin-right: 4px !important;
}

/* Sell form styles */

.sell h3 {
  line-height: 1.2;
}

.sell ul {
  margin-left: 30px;
  margin-bottom: 15px;
}

.sell ul > li {
  list-style: disc;
  padding-left: 10px;
  padding-bottom: 5px;
}

.sell ul.parsley-error-list,
.sell ul.parsley-error-list li {
  margin-left: 0;
  margin-bottom: 0;
  list-style: none;
  padding-left: 5px;
  padding-bottom: 0;
}

.success-intro i,
.error-intro i {
  font-size: 1.4em;
  float: left;
}

.success-intro {
  color: #228b22;
}

.error-intro {
  color: #dc1f26;
}

.success-intro p,
.error-intro p {
  display: inline-block;
  font-size: 1.2em;
  font-weight: bold;
  padding-left: 8px;
  margin-bottom: 20px;
  width: 90%;
}

/*adding inputs*/
.input_fields_wrap,
.input_fields_wrap > div {
  position: relative;
}

.input_fields_wrap .fa-plus,
.input_fields_wrap .fa-minus {
  position: absolute;
  right: -25px;
  top: 10px;
  cursor: pointer;
}

.sell-header {
  background: #fff url("//assets.secure-tix.com/sell-tickets.jpg") no-repeat right 30%;
  padding: 50px 30px;
}

.sell-header > h2 {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* ==================
  Form Validation
===================== */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.checkout ::-ms-clear,
.hosted-field ::-ms-clear {
    display: none;
    height: 0;
    width: 0;
}
.checkout label i {
  color: #aaa;
  font-size: 10px;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1;
}
/* Override native browser validation styling for parsley validated fields */
input.parsley-validated:focus:invalid{
  color: #555;
  box-shadow:none;
}
/* Make parsley errors show in red */
input.parsley-validated.parsley-error{
  color: #b94a48;
}
/* Add a glow to the error field on focus and keep the red text until valid */
input.parsley-validated.parsley-error:focus{
   color: #b94a48;
   box-shadow: 0 0 6px #f8b9b7;
}
.parsley-error-list,
.parsley-error-list li {
  margin: 0;
  list-style: none;
  color: firebrick;
  font-size: 11px;
}
input.parsley-error,
input.parsley-validated {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMThweCIgaGVpZ2h0PSIzNXB4IiB2aWV3Qm94PSIwIDAgMTggMzUiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE4IDM1IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiMxRTZGOTEiIGQ9Ik03LjY1Miw2LjM1Nmw1Ljg0My01Ljc2YzAuMzI1LTAuMzIxLDAuODU2LTAuMjk1LDEuMTQ4LDAuMDU2bDAsMGMwLjI1OSwwLjMxLDAuMjM4LDAuNzY2LTAuMDQ4LDEuMDUyDQoJTDcuNjUyLDguNjQ4Yy0wLjMwNSwwLjMwNS0wLjc5OSwwLjMwNS0xLjEwNCwwTDMuMzc3LDUuNDc3QzMuMDYyLDUuMTYyLDMuMDczLDQuNjQ5LDMuNDAxLDQuMzQ5bDAuMDQ4LTAuMDQ0DQoJYzAuMzA4LTAuMjgzLDAuNzg0LTAuMjcyLDEuMDgsMC4wMjNsMi4wMjMsMi4wMjNDNi44NTUsNi42NTUsNy4zNDcsNi42NTcsNy42NTIsNi4zNTZ6Ii8+DQo8cGF0aCBmaWxsPSIjQjIyMjIyIiBkPSJNMTMuMTMxLDMzLjA1bC0yLjE2Mi0yLjFjLTAuMzY4LTAuMzU3LTAuMzcyLTAuOTQ3LTAuMDA5LTEuMzA5bDEuOTY2LTEuOTY2DQoJYzAuMzY4LTAuMzY4LDAuMzU3LTAuOTY5LTAuMDI0LTEuMzIzbC0wLjA1Mi0wLjA0OGMtMC4zNjItMC4zMzYtMC45MjYtMC4zMjYtMS4yNzUsMC4wMjRMOS42NSwyOC4yNQ0KCWMtMC4zNTksMC4zNTktMC45NDEsMC4zNTktMS4zLDBsLTEuOS0xLjljLTAuMzU5LTAuMzU5LTAuOTQxLTAuMzU5LTEuMywwbDAsMGMtMC4zNTksMC4zNTktMC4zNTksMC45NDEsMCwxLjNsMS45LDEuOQ0KCWMwLjM1OSwwLjM1OSwwLjM1OSwwLjk0MSwwLDEuM2wtMi4xNzYsMi4xNzZjLTAuMzY4LDAuMzY4LTAuMzU3LDAuOTY5LDAuMDI1LDEuMzIzbDAuMDUyLDAuMDQ4DQoJYzAuMzYyLDAuMzM2LDAuOTI2LDAuMzI2LDEuMjc1LTAuMDI0bDIuMTMzLTIuMTMzYzAuMzU1LTAuMzU1LDAuOTMtMC4zNTksMS4yOS0wLjAwOWwyLjIsMi4xMzhjMC4zNiwwLjM1LDAuOTM1LDAuMzQ2LDEuMjktMC4wMDloMA0KCUMxMy41MDMsMzMuOTk3LDEzLjQ5OSwzMy40MDgsMTMuMTMxLDMzLjA1eiIvPg0KPC9zdmc+DQo=');
  background-repeat: no-repeat;
  background-position: 100% -999px;
}
.lt-ie9 input.parsley-error,
.lt-ie9 input.parsley-validated {
  background-image: url("/v3/images/sprites/validation.png");
}
.checkout input.radio.required,
.checkout input.checkbox.parsley-validated {
  background-image: none;
}
.checkout #checkoutForm input[type="radio"].parsley-error,
.checkout #checkoutForm input[type="radio"].parsley-validated,
.checkout #checkoutForm input[type="checkbox"].parsley-validated,
.checkout #checkoutForm input[type="checkbox"].parsley-error {
  background-image: none !important;
}
input.parsley-error,
input.parsley-validated.parsley-success.invalid,
input.parsley-validated.parsley-success.invalid:focus {
  background-position: 98% -16px;
}
input.required.parsley-validated:focus,
select.required.parsley-validated:focus {
  outline: none;
  border-color: #ccc;
}
input.parsley-validated.parsley-success {
  background-position: 98% 9px;
  border-color: #ccc;
}

/* Credit Card sprite styling */

.cc-container,
.cc-container * { transition: none !important; }

.cc-container:before {
  content: "";
  position: absolute;
  width: 160px;
  height: 25px;
  top: -2px;
  right: 25%;
  background-image: url('//assets.secure-tix.com/checkout/credit-cards.svg');
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 160px;
  z-index: 9999;
}

@media (min-width: 1200px) {
  .cc-container:before {
    top: 28px;
    right: 175px;
  }
}

@media (min-width: 881px) {
  .rocket-miles-banner-mobile {
    display:none;
  }
}

@media (max-width: 625px) {
  .cc-container:before {
    top: -7px;
    right: 0;
  }
  .hosted-field {
    height: 30px;
  }
}

@media all and (-webkit-min-device-pixel-ratio:0) {
  .iOS .hosted-field {
    padding-top: 0; /*text sits low in new inputs on ipad*/
  }
}

.lt-ie9 .cc-container:before {
  background-image: url('//assets.secure-tix.com/checkout/credit-cards.png');
  top: -8px;
  right: 25%;
}
.lt-ie9 .hosted-field {
  height: 30px;
}

.cc-container.visa:before { background-position: 100% -25px; }
.cc-container.master-card:before {  background-position: 100% -51px; }
.cc-container.american-express:before {  background-position: 100% -76px; }
.cc-container.discover:before {  background-position: 100% -101px; }

/* Unsupported Browsers */
.browserUnsupported {
  text-align: center;
}
.browserUnsupported h3.main-message {
  font-family: 'bebas_neueregular', sans-serif;
  line-height: 1;
}

.browserUnsupported p + h3 {
  font-size: 1em;
  padding-top: 25px;
}

.browserUnsupported .supported-browsers {
  background: url('//assets.secure-tix.com/checkout/browsers-ie9-plus-scaled.png') no-repeat 0 0;
  display: inline-block;
  height: 64px;
  width: 276px;
}


/* Braintree hosted fields */
.cc-container,
.hosted-field {
  position: relative;
}

body .hosted-field.braintree-hosted-fields-invalid {
  margin-bottom: 25px !important;
}

/* Invalid entry feedback */
.hosted-field.braintree-hosted-fields-invalid:after {
  content: "Card number invalid or not accepted";
  position: absolute;
  bottom: -25px;
  left: 0;
  display: block;
  width: 100%;
  color: #D01215;
  font-size: 11px;
}

#cvv.hosted-field.braintree-hosted-fields-invalid:after,
#existing-cvv.hosted-field.braintree-hosted-fields-invalid:after { content: "A valid security code is required"; }
#expiration-date.hosted-field.braintree-hosted-fields-invalid:after { content: "A valid expiration date is required"; }
#expiration-month.hosted-field.braintree-hosted-fields-invalid:after { content: "A valid month is required"; }
#expiration-year.hosted-field.braintree-hosted-fields-invalid:after { content: "A valid year is required"; }


/* add x for invalid/check for valid entries */
.hosted-field.braintree-hosted-fields-invalid:before,
.hosted-field.braintree-hosted-fields-valid:before {
  content: "";
  position: absolute;
  top: 10px;
  right: 5px;
  display: block;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMThweCIgaGVpZ2h0PSIzNXB4IiB2aWV3Qm94PSIwIDAgMTggMzUiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE4IDM1IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwb2x5Z29uIGZpbGw9IiM2MEFGNjAiIHBvaW50cz0iNy4xLDYuOSAxNC4xLDAgMTUuMSwxLjIgNy4xLDkuMiAyLjgsNC45IDQsMy44ICIvPg0KPHBvbHlnb24gZmlsbD0iI0REMjIyOCIgcG9pbnRzPSIxMy44LDMzLjcgMTAuMywzMC4zIDEzLjYsMjcgMTIuMiwyNS43IDksMjguOSA1LjgsMjUuNyA0LjUsMjcgNy43LDMwLjIgNC4yLDMzLjcgNS42LDM1IDksMzEuNiANCgkxMi41LDM1ICIvPg0KPC9zdmc+DQo=");
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
}

.hosted-field.braintree-hosted-fields-valid:before {
  background-position: 0 0;
}

.hosted-field.braintree-hosted-fields-invalid:before {
  background-position: 0px -25px;
}

.hosted-field input {
  font-family: "Proxima Nova" sans-serif;
  font-size: 14px;
}

.ticketInsurance { display: none; }

/* Checkout styles */


/* animated loader for ajax user feedback */
.loading-wrapper {
  display: none;
  width: 100px;
  height: 75px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  padding-top: 25px;
  background: #666;
  border-radius: 3px;
  background: rgba(0,0,0,.4);
  z-index: 999;
}
.loading {
  margin: 0 auto;
  background-image: none;
  box-sizing: border-box;
  border: 4px solid #fff;
  border-top-color: #2abff9;
  border-radius: 100%;
  height: 44px;
  width: 44px;
  -webkit-animation: spin .5s infinite linear;
  -moz-animation: spin .5s infinite linear;
  -ms-animation: spin .5s infinite linear;
  -o-animation: spin .5s infinite linear;
  animation: spin .5s infinite linear;
}
.lt-ie9 .loading {
  background: #666666 url('/v4/images/tfs/loading-checkout.gif') center center no-repeat;
  border: none;
  border-radius: 0;
}


@keyframes spin {
 from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
 }
 to {
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    transform: rotate(359deg);
 }
}

@-moz-keyframes spin {
 from {
   -moz-transform: rotate(0deg);
   transform: rotate(0deg);
 }
 to {
   -moz-transform: rotate(359deg);
   transform: rotate(359deg);
 }
}

@-webkit-keyframes spin {
 from {
   -webkit-transform: rotate(0deg);
   transform: rotate(0deg);
 }
 to {
   -webkit-transform: rotate(359deg);
   transform: rotate(359deg);
 }
}

@-ms-keyframes spin {
 from {
   -ms-transform: rotate(0deg);
   transform: rotate(0deg);
 }
 to {
   -ms-transform: rotate(359deg);
   transform: rotate(359deg);
 }
}

@-o-keyframes spin {
 from {
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
 }
 to {
   -o-transform: rotate(359deg);
   transform: rotate(359deg);
 }
}
#deliveryMethodAlert {
  display: none;
}

input[name="wizard.account.email"] {
  padding-right: 30px;
}
select.saved-info {
  min-height: 30px;
  margin-top: 5px;
}
#checkoutForm select:focus {
  border-color: #ccc;
  outline: 0;
  outline: 0 \9;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  -moz-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(82,168,236,.6);
}
.paypal-phone i {
  font-weight: normal;
}
.lt-ie9 #ccExpirationDateError {
  display: inline-block;
  min-width: 40px;
}
.lt-ie8 #ccExpirationDateError {
  display: inline;
  min-width: auto;
}
.form-errors .error-container {
  padding-bottom: 20px;
}
.error-item {
  display: list-item;
  margin-left: 20px;
}
.error-text {
  color: gray;
  margin: 20px;
}
.error-container a {
  margin: 20px;
}
/*new address/new credit card*/
.checkout-input-form label {
  margin: 3px 0 2px;
}
.lt-ie8 .checkout-input-form select,
.lt-ie8 .checkout-input-form input[type="text"]{
  width: 95%;
  margin-top: 0;
}
.creditCardCheckout .expirationDate select {
  float: left;
  width: 49%; /*month and year dropdown on same line*/
}
.lt-ie8 .creditCardCheckout .expirationDate select {
  width: 47%;
}
.creditCardCheckout .expirationDate select + select {
  margin-left: 2%;
}
#existingCvsCodeContainer input,
.cvcContainer input {
  margin-right: 5px;
  width: 60px;
}
.checkout .paymentAndBilling .controls input.radio#existingCreditCard {
  margin-top: 6px\9;
  margin-left: 0\9;
}
/*radio/checkbox spacing corrects*/
.lt-ie8 .checkbox input[type="checkbox"],
.lt-ie8 .radio input[type="radio"]{
    margin-top: 3px;
}
.gte-ie9 .checkbox input[type="checkbox"],
.gte-ie9 .radio input[type="radio"] {
    margin-top: -1px;
}
.lt-ie9 .radio,
.lt-ie9 .checkbox {
  min-height: 0;
}
.well,
.well-small {
  border-radius: 0;
  margin-bottom: 30px;
}
.ticketDetails {
  margin-bottom: 15px;
}
.productionName {
  font-size: 18px;
  line-height: 1;
  text-rendering: auto;
}
.checkout .ticketDetails .head-title,
.checkout .orderSecured .head-title,
.checkout .custom_infobox_content .head-title {
  border: none;
  text-transform: capitalize;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  color: #333;
  font-size: 18px;
  margin-bottom: 0;
}
.checkout select.checkout-quantity {
  width: auto;
}
.accordion {
  margin: 10px 0;
}
.orderSummary .well-small hr {
  margin:10px 0;
}
.orderSummary .total-charges dt {
  text-align: right;
  width: 45%;
}
.lt-ie8 .orderSummary .total-charges dt {
  float: left;
}
.lt-ie8 .orderSummary .total-charges dd {
  float: left;
  width: 49%;
}
.orderSummary #aipServiceCharge{
  margin: 0px;
  font-size: 9px;
  display: block;
}
.orderSummary .total-charges .totalCharge{
  font-size: 13px;
  font-weight: bold;
}
.orderSummary .total-charges dl.no-bold, .orderSummary .total-charges dl.no-bold dt, .orderSummary .total-charges dl.no-bold dd{
    font-size: 13px;
    font-weight: normal;
}
.orderSummary #subtotalSection {
  font-size: 13px;
}
.orderSummary .event-date-name i.fa {
  width: 20px;
  text-align: center;
}
.event-started {
  background-color: #428bca;
  color: #ffffff;
  border-radius: 3px;
  padding: 3px 5px;
}
.receipt-event-started {
  margin-top: 5px;
}
#collapse-purchase-info {
  position: relative;
}
#collapse-purchase-info h4 a:after {
  content: "\f147";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  font-size: 18px;
  position: absolute;
  top: 17px;
  left: 208px;
  text-decoration: none !important;
}
#collapse-purchase-info h4 a:after:hover {
  text-decoration: none !important;
}
#collapse-purchase-info h4 a.collapsed:after {
  content: "\f196";
}
.accordion i.fa {
  width: 20px;
  text-align: center;
}

/* PayPal styling */
.paypal-checkout.form-horizontal .controls {
  margin-left: 20px;
  display: inline;
}
.paypal-checkout.form-horizontal .controls + .controls {
  margin-left: 0;
}

/* Apple Pay styling */
.applePayCheckout .apple-pay-logo {
  border: 1px solid #000;
  -webkit-border-radius: 3px; -moz-border-radius: 3px; border-radius: 3px;
  padding: 8px 16px;
  margin-right: 12px;
}
.applePayCheckout span {
  display: inline-block;
  vertical-align: middle;
  width: 80%;
  width: calc(100% - 95px);
}
@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
    box-sizing: border-box;
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: buy;
    height: 38px;
    padding: .1rem;
    width: auto;
    vertical-align: bottom;
  }

  .apple-pay-button-with-text > * {
    display: none;
  }

  .apple-pay-button-black-with-text {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-white-with-text {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line-with-text {
    -apple-pay-button-style: white-outline;
  }
}
@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button-with-text {
    --apple-pay-scale: 1; /* (height / 32) */
    display: inline-flex;
    justify-content: center;
    font-size: 1.2rem;
    border-radius: 5px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 36px;
    max-height: 64px;
    padding: .1rem;
    height: 38px;
  }
  .apple-pay-button-black-with-text {
    background-color: black;
    color: white;
  }
  .apple-pay-button-white-with-text {
    background-color: white;
    color: black;
  }
  .apple-pay-button-white-with-line-with-text {
    background-color: white;
    color: black;
    border: .5px solid black;
  }
  .apple-pay-button-with-text.apple-pay-button-black-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .apple-pay-button-with-text.apple-pay-button-white-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-with-text.apple-pay-button-white-with-line-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-with-text > .text {
    font-family: -apple-system;
    font-size: calc(1em * var(--apple-pay-scale));
    font-weight: 300;
    align-self: center;
    margin-right: calc(2px * var(--apple-pay-scale));
  }
  .apple-pay-button-with-text > .logo {
    width: calc(35px * var(--scale));
    height: 100%;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 0 50%;
    margin-left: calc(2px * var(--apple-pay-scale));
    border: none;
  }
}

/* Duplicate Order Check */
.duplicateOrderCheck .widgetContent {
  overflow: hidden;
}
.duplicateOrderCheck table {
  border: 1px solid #e5e5e5;
  margin-bottom: 20px;
  width: 100%;
}
.duplicateOrderCheck tr.headerBar {
  font-weight: bold;
}
.duplicateOrderCheck tr {
  border-bottom: 1px solid #e5e5e5;
}
.duplicateOrderCheck td {
  padding: 6px 20px;
}
.duplicateOrderCheck .form-horizontal .control-label {
  margin-bottom: 20px;
}

/* Single Order Lookup */
.receipt-section-labels {
  cursor: text;
  color: #555;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 2px !important;
  padding-bottom: 2px;
  border-bottom: 1px dotted #ddd;
  display: inline-block;
}
.receiptAddTotal {
  text-align: right;
}
.receiptAddItem {
  text-align: left;
  font-weight: bold;
}
.myAccountReceipt .orderConfirmation .widget {
  box-shadow: none;
}
.contact-us {
  font-size: 0.9em;
}
.contact-us a {
  word-break: break-all;
  word-wrap: break-word;
}

.errorMessage {
  background-color: #FCE2E2;
  border: 1px solid #FACBCB;
  color: #DF3338;
  padding: 20px;
  display: block;
}
.checkout .errorMessage {
  background-color: transparent;
  border: none;
  padding: 0;
}

.checkout .terms-of-use {
  padding: 15px;
  margin: 15px 0;
  max-height: 175px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  font-size: .8em;
   -webkit-overflow-scrolling: touch;
}
.checkout .agree-to-print {
    margin-bottom: 10px;
}

.checkout .terms-of-use h2 {
  font-size: 16px;
  line-height: 1;
  margin: .5em 0;
}
.checkout .terms-of-use h3 {
  font-size: 14px;
  line-height: 1;
  margin: .5em 0;
}

#zone-seating-span {
  text-decoration: underline;
  cursor: pointer
}

.basic-modal {
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1000;
  background: rgba(0, 0, 0, .5);
}

.info-dialog {
  color: #5c5c5c;
  max-width: 515px;
  right: 0;
  left: 0;
  bottom: 0;
  width: 515px;
  margin: auto;
  background-color: white;
  padding: 25px;
  text-align: center;
  border-radius: 3px;
  box-shadow: 2px 2px 6px rgba(0,0,0,.5);
  position: relative;
  display: table;
  top: 30%;
}

.popup-title {
  color: #333;
  font-size: 1.75em;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 15px;
}

.popup-body {
  text-align: left;
}

.popup-action {
  padding-top: 20px;
  border-top: 2px solid rgba(204,204,204,.5);
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  justify-content: flex-end;
  -ms-flex-pack: end;
}

.popup-action .popup-cancel {
  transition: all 0.1s ease-in-out;
  color: #4481C4;
  cursor: pointer;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 767px) {
  .info-dialog {
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: 1000;
    padding: 1.2em;
    width: 300px;
    border-radius: 3px;
    box-sizing: border-box;
  }

  .popup-action .popup-cancel {
    color: #666;
    font-size: 1em;
  }

  .popup-action {
    padding-top: 20px;
    border-top: none;
  }
}

@media (max-width: 480px) {
  .info-dialog {
    margin: 0;
  }
}

/* Etickets download */
.etickets_downloadItem {
  margin-top: 20px;
}
.etickets > ul,
.list-bullets {
  margin-left: 2em;
  list-style-type: disc;
}
.etickets_downloads {
  text-align: center;
}

@media print {
  .accountAssistant {
    display: none;
  }
  .head-title {
    color: #000;
    border: none;
    text-align: left;
  }
  h1,
  .goose-title {
    display: none !important;
  }
}

.myAccountAuthCenter .container {
  width: 500px;
}

.headlineA {
  display: block;
  font-weight: bold;
  margin-bottom: .5em;
}
.receipt-order-summary .row-container {
  margin-bottom: 2em;
}

.accordion-group {
  padding: 5px;
  margin-bottom: 5px;
}
.accordion-group:nth-child(odd) {
  background: #f8f8f8;
}

/* =====================================

  3. Overrides

====================================== */

select, textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], .uneditable-input, .hosted-field {
  border-radius: 3px;
  border-color: #ccc;

}
.btn,
.input-append .add-on:last-child, .input-append .btn:last-child, .input-append .btn-group:last-child > .dropdown-toggle {
  border-radius: 0;
}
.input-append input, .input-append select, .input-append .uneditable-input {
  border-radius: 3px 0 0 3px;
}
textarea:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="date"]:focus, input[type="month"]:focus, input[type="time"]:focus, input[type="week"]:focus, input[type="number"]:focus, input[type="email"]:focus, input[type="url"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="color"]:focus, .uneditable-input:focus, #checkoutForm select:focus, .hosted-field.braintree-hosted-fields-focused {
  border-color: #57ACCF !important;
  outline: 0;
  outline: 0 \9;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.alert {
  border-radius: 0;
}
.receiptPrint {
  display: none !important;
}
.lt-ie8 .agree-terms {
  margin-left: 0 !important;
}
.resend-tickets-message {
  margin: 10px 0 0;
  display: none;
}

.none {
  display: none;
}

/* =====================================

  4. 3rd Party

====================================== */

/*=============================================================================
  4a. SPACING HELPERS
  p,m = padding,margin
  a,t,r,b,l,h,v = all,top,right,bottom,left,horizontal,vertical
  s,m,l,n = small(5px),medium(10px),large(20px),none(0px)
  https://github.com/stubbornella/oocss/tree/master/core
=============================================================================*/

.ptn,.pvn,.pan {padding-top:0px}
.pts,.pvs,.pas {padding-top:5px}
.ptm,.pvm,.pam {padding-top:10px}
.ptl,.pvl,.pal {padding-top:20px}
.ptx,.pvx,.pax {padding-top:40px}
.prn,.phn,.pan {padding-right:0px}
.prs,.phs,.pas {padding-right:5px}
.prm,.phm,.pam {padding-right:10px}
.prl,.phl,.pal {padding-right:20px}
.prx,.phx,.pax {padding-right:40px}
.pbn,.pvn,.pan {padding-bottom:0px}
.pbs,.pvs,.pas {padding-bottom:5px}
.pbm,.pvm,.pam {padding-bottom:10px}
.pbl,.pvl,.pal {padding-bottom:20px}
.pbx,.pvx,.pax {padding-bottom:40px}
.pln,.phn,.pan {padding-left:0px}
.pls,.phs,.pas {padding-left:5px}
.plm,.phm,.pam {padding-left:10px}
.pll,.phl,.pal {padding-left:20px}
.plx,.phx,.pax {padding-left:40px}
.mtn,.mvn,.man {margin-top:0px}
.mts,.mvs,.mas {margin-top:5px}
.mtm,.mvm,.mam {margin-top:10px}
.mtl,.mvl,.mal {margin-top:20px}
.mrn,.mhn,.man {margin-right:0px}
.mrs,.mhs,.mas {margin-right:5px}
.mrm,.mhm,.mam {margin-right:10px}
.mrl,.mhl,.mal {margin-right:20px}
.mbn,.mvn,.man {margin-bottom:0px}
.mbs,.mvs,.mas {margin-bottom:5px}
.mbm,.mvm,.mam {margin-bottom:10px}
.mbl,.mvl,.mal {margin-bottom:20px}
.mln,.mhn,.man {margin-left:0px}
.mls,.mhs,.mas {margin-left:5px}
.mlm,.mhm,.mam {margin-left:10px}
.mll,.mhl,.mal {margin-left:20px}
.mra,.mha {margin-right:auto}
.mla,.mha {margin-left:auto}
